import { defineStore } from 'pinia'

export const useFeaturesStore = defineStore('features', {
  state: () => ({
    features: []
  }),
  getters: {
    isFeatureEnabled: ({ features }) => (name) => features.some(({ feature_name: n, is_active: a }) => n === name && a)
  },
  actions: {
    async refresh() {
        const { ApiController } = await _getModules()
        const response = await ApiController.fetchCustomerFeatures()

        this.features = response
        await _trackGatekeeper(response)
    }
  }
})

const _trackGatekeeper = async (data) => {
  const { TagManager, GA4_CUSTOM_EVENT: { USER_DATA } } = await _getModules()
  setTimeout(() => {
    const settings = data.reduce((col, { feature_name: name, is_active: active }) => ({ ...col, [name]: active }),{})
    TagManager.pushEvent(USER_DATA, { gatekeeper_settings: settings })
  }, 0)}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: TagManager },
      { default: ApiController },
      { GA4_CUSTOM_EVENT }
    ] = await Promise.all([
      await import('@/services/analytics/TagManager'),
      await import('@/services/ApiController'),
      await import('@/constants/GlobalConstants')
    ])
    cache = { ApiController, TagManager, GA4_CUSTOM_EVENT }
    return cache
  }
})()
