import { defineStore } from 'pinia'
import CmsBlockModel from '@/modules/cms/CmsBlockModel'

export const DEFAULTS = [
  'fe_app_navigation',
  'header_logo_v2',
  'promotion_banner_v2',
  'before_header_v2'
]

export const useCmsBlocks = defineStore('cmsBlocks', {
  state: () => ({ blocks: {} }),
  getters: {
    getContent: ({ blocks }) => (key) => blocks[key]
  },
  actions: {
    async fetchOne(id) {
      if (this.blocks[id]) return this.blocks[id]

      const { ApiController } = await _getModules()
      const { block_content_css: css, block_content_js: js, ...response } = await ApiController.fetchCmsBlock( id)
      const data = { ...response, css, js }

      const block = new CmsBlockModel(data)
      this.blocks[id] = block
      return block
    },
    async fetchDefaults() {
      DEFAULTS.forEach((id) => { this.fetchOne(id) })
    }
  },
})

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const { default: ApiController } = await import('@/services/ApiController')

    cache = { ApiController }
    return cache
  }
})()
