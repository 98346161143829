import { defineStore } from 'pinia'
import UpsellProduct from '@/modules/product/UpsellProduct'

// preventing circular dependency
let cart = {
  cartId: null,
  vendors: [],
  addItem: () => {},
  removeItem: () => {}
}
let isUpdating = false

export const useUpsellStore = defineStore('upsell', {
  state: () => ({
    config: {},
    products: [],
    isUpdating: false
  }),
  getters: {
    getConfig: ({ config }) => config,
    metaProducts: ({ products }) => products,
    selectedUpsell: () => cart.vendors.reduce((collected, { items }) => [...collected, ...items], [])
      .find(({ is_upsell: iu }) => iu)
  },
  actions: {
    reset() {
      this.config = {}
      this.products = []
    },
    async refreshUpsell() {
      const { ApiController } = await _getModules()
      if (isUpdating || !cart.cartId) return

      isUpdating = true

      const { config, products } = await ApiController.fetchUpsell(cart.cartId)

      this.config = config ?? {}
      this.products = products.map((p) =>
        _cb(this.selectedUpsell?.product_id)(new UpsellProduct({ ...p, campaign_id: config.upsell_campaign_id }))
      )

      isUpdating = false
    },
    async selectUpsell({ product, itemListId, index }) {
      const payload = {
        productId: +product.product_id,
        campaignId: +product.campaign_id,
        qty: 1,
        manufacturer: product.manufacturer,
        name: product.name,
        price: 0,
        originalPrice: product.price.original,
        variant: product.ga_data,
        upsellId: this.config.cart_rule_id,
        itemListId,
        index
      }

      try {
        await cart.addItem(payload)
        this.products = this.products.map(_cb(product.product_id))
      } catch (_) {
        // Don't update
      }
    },
    async removeUpsell({ cartItemId }) {
      try {
        await cart.removeItem({ item: { cart_item_id: cartItemId } })
        this.products = this.products.map(_cb(null))
      } catch (_) {
        // Don't update
      }
    }
  },
})

const _cb = (selected) =>
  (product) => ({ ...product, is_selected: product.product_id === selected })

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: ApiController },
      { useCartStore }
    ] = await Promise.all([
      await import('@/services/ApiController'),
      await import('@/stores/cart'),
    ])
    cache = { ApiController }
    cart = useCartStore()
    return cache
  }
})()
