import { defineStore } from 'pinia'
import CustomerMetaSchema from '@/modules/schemas/customer/CustomerMetaSchema'

const ACCESS_TOKEN_EXPIRATION = 1000 * 60 * 59
const SOFT_LOGIN_COOKIE = 'soft_login'
const LAST_REFRESH = 'last_refresh'
const API_KEY_AGE = 'apiKey_age'
const API_KEY = 'apiKey'
const PAYMENT_REQUIRED_REGEX = /-opc-p\d+-c\d+/
export const useCustomerStore = defineStore('customer', {
  state () {
    return {
      pending: false,
      profile: CustomerMetaSchema.fields,
      payment: {
        token: null
      },
      active: false,
      softLoggedIn: false,
      isLoginShowing: false,
      presets: {
        email: null,
        password: null
      },
      hooks: {
        afterLogin: null,
        afterLogout: null,
        afterRegister: null
      }
    }
  },
  getters: {
    customerProfile: ({ profile }) => profile,
    customerSoftLoggedIn: ({ active, softLoggedIn }) => active || softLoggedIn,
    customerActive: ({ active }) => active,
    customerIsAdmin: ({ profile: { is_admin: isAdmin } }) => isAdmin,
    customerIsPending: ({ pending }) => pending,
    customerPaymentToken: ({ payment: { token } }) => token,
    adminLoggedInAs: ({ profile: { admin_data: adminData } }) => adminData?.admin_customer_id,
    isNewsletterSubscribed: ({ profile: { is_newsletter_subscribed: isNewsletterSubscribed } }) => isNewsletterSubscribed,
    customerPresets: ({ presets }) => presets
  },
  actions: {
    async establishSession ( ) {
      // already in progress, bail out
      if (this.pending) return

      this.pending = true
      // check if there has been an active session previously
      await this.checkPreviousSession()
      // check for soft login
      await this.checkSoftLogin()
      void this.fetchPaymentToken()

      // no soft login nor active session, bail out
      if (!this.active && !this.softLoggedIn) {
        this.pending = false
        return
      }

      // get profile
      await this.fetchProfile()
      if (!this.profile.customer_id) {
        /*
          Login was not successful, bail out
          This can happen if the app is recreated and the CookieHelper.isSet tests a httpOnly cookie, but it doesn't expire before the next load (1sec)
         */
        this.pending = false
        this.active = false
        this.softLoggedIn = false
        return
      }

      try {
        // get a fresh access token, if soft login: nothing to do here
        await _handleRefreshToken({ profile: this.profile, softLoggedIn: this.softLoggedIn })
      } catch (e) {
        await _handleError(e)
      }

      // all done, session established
      this.pending = false
    },
    async fetchProfile () {
      // profile is already setup, bail out
      if (this.profile.customer_id) return

      // get profile otherwise
      try {
        const { ApiController } = await _getModules()
        const customerProfile = await ApiController.fetchCustomerProfile()

        this.profile = customerProfile
        setTimeout(async () => {
          await _initTagManager(customerProfile)
        }, 0)
      } catch (e) {
        this.softLoggedIn = false
        console.error(e)
      }
    },
    async fetchPaymentToken () {
      // token not needed or is already setup, bail out
      if (!PAYMENT_REQUIRED_REGEX.test(location.pathname) || this.payment.token) return

      // get token otherwise
      const { ApiController } = await _getModules()
      const { braintree_client_token: bct } = await ApiController.fetchCustomerBraintreeToken()
      this.payment.token = bct
    },
    async waitUntilPending () {
      while (this.customerIsPending) await _wait()
      return Promise.resolve()
    },
    async waitForToken () {
      while (!this.customerPaymentToken) await _wait()
      return Promise.resolve()
    },
    async checkPreviousSession () {
      const { CookieHelper } = await _getModules()
      const active = CookieHelper.isSet(API_KEY_AGE) || CookieHelper.isSet(API_KEY)

      if (active) {
        CookieHelper.invalidate(SOFT_LOGIN_COOKIE)
      }
      this.active = active
    },
    async checkSoftLogin () {
      if (this.active) return
      this.softLoggedIn = await _handleSoftLogin()
    },
    async logout () {
      const { ApiController, CookieHelper } = await _getModules()
      await ApiController.logoutCustomer()
      CookieHelper.invalidate('cartUuid')

      this.active = false
      this.softLoggedIn = false
      this.profile = CustomerMetaSchema.fields
    },
    async login ({ email, password }) {
      const { ApiController, MessageHelper } = await _getModules()
      try {
        await ApiController.loginCustomer({ email, password })
        await this.establishSession()
        _runHook(this.hooks.afterLogin)
      } catch (e) {
        const { status, cause } = e
        if(cause === 1014) {
          this.presets = { email, password }
          await MessageHelper.addErrorMessage('two_factor_authentication_required')
          throw e
        }

        await MessageHelper.addErrorMessage(status === 401 || cause === 1013? 'please_check_your_data' : 'unknown_error')
        throw e
      }
    },
    async login2FA ({ code }) {
      const { email, password } = this.presets
      const { ApiController, MessageHelper } = await _getModules()
      const MESSAGES = {
        401: 'please_check_your_data',
        500: 'server_error',
      }
      try {
        await ApiController.verifyTwoFactorAuth({ email, password, code })
        await ApiController.loginCustomer({ email, password })
        await this.establishSession()
        _runHook(this.hooks.afterLogin)
      } catch ({ status, cause }) {
        if(cause === 1021) {
          await MessageHelper.addErrorMessage('invalid_2fa_code')
          await Promise.reject (new Error('invalid_2fa_code'))
        }
        else {
          await MessageHelper.addErrorMessage(MESSAGES[status] || 'unknown_error')
        }
      }
    },
    async register ({ email, firstName, lastName, gender, title, password, dob }) {
      const { ApiController } = await _getModules()
      await ApiController.registerCustomer({ email, firstName, lastName, gender, title, password, dob })
      await ApiController.loginCustomerWithApiKey()
      await this.establishSession()
    },
    async showLogin ({ visible, email, hook, hookParams }) {
      this.presets.email = email ?? null
      this.hooks.afterLogin = hook ? () => hook(...hookParams) : null
      this.isLoginShowing = visible ?? false
    }
  }
})

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: SiteConfig },
      { default: TagManager },
      { default: ApiController },
      { default: CookieHelper },
      { default: MessageHelper }
    ] = await Promise.all([
      await import('@/services/SiteConfig'),
      await import('@/services/analytics/TagManager'),
      await import('@/services/ApiController'),
      await import('@/services/helpers/CookieHelper'),
      await import ('@/services/helpers/MessageHelper')
    ])
    cache = { SiteConfig, TagManager, ApiController, CookieHelper, MessageHelper }
    return cache
  }
})()

const _initTagManager = async profile => {
  const { TagManager } = await _getModules()
  void TagManager.initCustomerData(profile)
}

const _handleRefreshToken = async ({ profile: { admin_data: admin, customer_id: id }, softLoggedIn }) => {
  // no customer or soft login
  if (softLoggedIn) return
  // admin is logged in as customer
  if (admin?.admin_customer_id && admin.admin_customer_id !== id) return

  const { ApiController, CookieHelper } = await _getModules()
  const lastRefresh = parseInt(CookieHelper.getCookie(LAST_REFRESH) || 0)
  const now = Date.now()

  const difference = now - lastRefresh

  if (difference < ACCESS_TOKEN_EXPIRATION) return

  await ApiController.refreshCustomerToken()
}

const _handleSoftLogin = async () => {
  const { CookieHelper } = await _getModules()

  const { groups: grpEmail } = /email=(?<email>[^&]*)/.exec(location.search) ?? {}
  const { groups: grpHash }  = /ph=(?<ph>[^&]*)/.exec(location.search) ?? {}

  if (grpEmail && grpHash) {
    const cookie = JSON.stringify({ ...grpEmail, ...grpHash })
    CookieHelper.setCookie(SOFT_LOGIN_COOKIE, cookie, 1)

    return true
  }
  return CookieHelper.getCookie(SOFT_LOGIN_COOKIE).length
}

const _handleError = async (e) => {
  console.error(e)
  const { SiteConfig, CookieHelper } = await _getModules()
  CookieHelper.invalidate('cartUuid')

  setTimeout(() => {
    location.replace(`https://${SiteConfig.domain}/logout?redirect_uri=${location.pathname}`)
  }, 0)
}

const _runHook = (hook) => {
  if (typeof hook !== 'function') {
    return
  }
  hook()
}

const _wait = async () => new Promise((resolve) => {
  setTimeout(resolve, 100)
})
