import { defineStore } from 'pinia'

const STASH_KEY = 'wishlist_stash'

export const useWishlistStore = defineStore('wishlist', {
  namespaced: true,
  state () {
    return {
      uuid: null,
      inProgress: false,
      inStock: {
        products: [],
        activePage: 1,
        totalPages: 0,
        totalCount: 0
      },
      soldOut: {
        products: [],
        activePage: 1,
        totalPages: 0,
        totalCount: 0
      },
      recent: []
    }
  },
  getters: {
    totalCount ({ inStock: { totalCount: inStockCount }, soldOut: { totalCount: soldOutCount } }) {
      return inStockCount + soldOutCount
    },
    updating ({ inProgress }) {
      return inProgress
    },
    inStockItems ({ inStock: { products } }) {
      return products
    },
    inStockTotalCount ({ inStock: { totalCount } }) {
      return totalCount
    },
    inStockTotalPages ({ inStock: { totalPages } }) {
      return totalPages
    },
    inStockActivePage ({ inStock: { activePage } }) {
      return activePage
    },
    soldOutItems ({ soldOut: { products } }) {
      return products
    },
    soldOutTotalCount ({ soldOut: { totalCount } }) {
      return totalCount
    },
    soldOutTotalPages ({ soldOut: { totalPages } }) {
      return totalPages
    },
    soldOutActivePage ({ soldOut: { activePage } }) {
      return activePage
    },
    addedRecently ({ recent }) {
      return (pId, cId) => recent.find(item => item.product_id === pId && item.campaign_id === cId)
    }
  },
  actions: {
    async removeItem ({ id, product_id: pId, campaign_id: cId }) {
      if (!await _preCheck()) return

      const { ApiController, BulkProductHelper, UtilityHelper } = await _getModules()
      this.inProgress = true

      try {
        await ApiController.removeFromWishList(id, this.uuid)
        await BulkProductHelper.eraseProductCache(pId, cId)

        void this.refreshWishlistItems()
        void this.refreshWishlistSoldOutItems()

        this.recent = this.recent.filter(item => item.id !== id)

        _addMessage('fe_app_delete_saved_item_success', 'icon_svg_checkmark')
      } catch (e) {
        UtilityHelper.noticeNRError(e, 'Error at wishlist [removeItem]')
        _addMessage('fe_app_delete_saved_item_fail', 'icon_svg_info')
      }

      this.inProgress = false
    },
    async addItem (payload)  {
      if (!await _preCheck(true)) return

      const { ApiController, BulkProductHelper, UtilityHelper, PAGE_URL, customerStore } = await _getModules()

      if (!customerStore.customerActive) {
        _stashItem(payload)
        location.replace('/login')
        return
      }
      try {
        const { item: { item_id: id } } = await ApiController.addToWishList(payload)
        await BulkProductHelper.eraseProductCache(payload.product_id, payload.campaign_id)
        this.recent = [...this.recent, { ...payload, id }]
        _addMessage('fe_app_add_saved_item_success', 'icon_svg_heart', PAGE_URL.WISHLIST)

        setTimeout(() => {
          this.refreshWishlistItems()
          this.refreshWishlistSoldOutItems()
          // transaction might not be finished yet on immediate call
        }, 500)

        return id
      } catch (e) {
        UtilityHelper.noticeNRError(e, 'Error at wishlist [addItem]')
        _addMessage('fe_app_add_saved_item_fail', 'icon_svg_alert')
      }
    },
    async refreshWishlistItems (page = 1) {
      if (!await _preCheck()) return

      this.inProgress = true
      const { UtilityHelper } = await _getModules()

      try {
        await _addStashedItemToWishlist(this.addItem)
        const { headers, uuid, items } = await _fetchWishlist(page, false)

        this.inStock.totalPages = parseInt(headers['x-total-pages'])
        this.inStock.totalCount = parseInt(headers['x-total-count'])
        this.inStock.activePage = page
        this.inStock.products = items
        this.uuid = uuid
      } catch (e) {
        UtilityHelper.noticeNRError(e, 'Error at wishlist [refreshItems]')
        _addMessage('fe_app_refresh_saved_item_fail', 'icon_svg_alert')
      }
      this.inProgress = false
    },
    async refreshWishlistSoldOutItems (page = 1) {
      if (!await _preCheck()) return

      const { UtilityHelper } = await _getModules()

      try {
        const { headers, items } = await _fetchWishlist(page, true)

        this.soldOut.totalPages = parseInt(headers['x-total-pages'])
        this.soldOut.totalCount = parseInt(headers['x-total-count'])
        this.soldOut.activePage = page
        this.soldOut.products = items
      } catch (e) {
        UtilityHelper.noticeNRError(e, 'Error at wishlist [refreshSoldOutItems]')
        _addMessage('fe_app_refresh_saved_item_fail', 'icon_svg_alert')
      }
    }
  }
})

const _addMessage = (translationKey, icon, link = null) => {
  setTimeout(async () => {
    const { MessageHelper } = await _getModules()
    await MessageHelper.addMessage(translationKey, icon, link)
  }, 0)
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: BulkProductHelper },
      { default: ApiController },
      { default: UtilityHelper },
      { PAGE_URL },
      { fetchProductWithIndex, matchItemWithProduct },
      { default: RequestHelper },
      { default: MessageHelper },
      { useCustomerStore }
    ] = await Promise.all([
      await import('@/modules/product/BulkProductHelper'),
      await import('@/services/ApiController'),
      await import('@/services/helpers/UtilityHelper'),
      await import('@/constants/GlobalConstants'),
      await import('@/services/helpers/BulkProductMatcher'),
      await import('@/services/helpers/RequestHelper'),
      await import('@/services/helpers/MessageHelper'),
      await import('@/stores/customer'),
    ])
    cache = {
      BulkProductHelper,
      ApiController,
      UtilityHelper,
      PAGE_URL,
      fetchProductWithIndex,
      matchItemWithProduct,
      RequestHelper,
      MessageHelper,
      customerStore: useCustomerStore()
    }
    return cache
  }
})()

const _preCheck = async (skipLoginCheck = false) => {
  if (location.pathname.includes('-opc')) return false

  const { customerStore } = await _getModules()
  await customerStore.waitUntilPending()
  // remove adminLoggedInAs check after VIP-9143
  if (customerStore.adminLoggedInAs) {
    return false
  }

  return skipLoginCheck || customerStore.customerSoftLoggedIn
}

const _fetchWishlist = async (page, soldOut = false) => {
  const { ApiController, fetchProductWithIndex, matchItemWithProduct } = await _getModules()
  const type = soldOut ? 'sold-out' : 'in-stock'

  const { headers, data: { uuid, items: responseData } } = await ApiController.refreshWishList(null, page, type)
  const endpoint = `wishlist?${type}`

  const indexed = await fetchProductWithIndex(responseData)
  const items = matchItemWithProduct(responseData, indexed, { endpoint }) ?? []

  return { headers, uuid, items }
}

const _stashItem = (item) => {
  sessionStorage.setItem(STASH_KEY, JSON.stringify(item))
}

const _addStashedItemToWishlist = async (addItem) => {
  const item = sessionStorage.getItem(STASH_KEY)
  if (!item) return

  await addItem(JSON.parse(item))
  setTimeout(() => { sessionStorage.removeItem(STASH_KEY) }, 0)
}
