import { defineStore } from 'pinia'

export const usePdpStore = defineStore('pdp', {
  namespaced: true,
  state() {
    return {
      metaProduct: {
        milesandmore: {},
        recommendation: {},
      },
      apiProduct: {
        product_id: null,
        campaign_id: null,
        general: {},
        description: {},
        details: {},
        design: {},
        stocks: [],
        awards: [],
        price: {},
        legal: {
          reference_unit: '',
          reference_unit_contents: 0
        }
      },
      apiV1Product: {
        description: {},
        details: {},
        design: {},
        expert_reviews: {}
      },
      calculated: {
        isSimple: true,
        isBundle: false,
        backToReferrer: '',
        mmEnabled: false,
        isVisible: true,
        preSelectedQty: null,
        priceObject: {
          price: null,
          savings: null,
          unitPrice: null,
          currency: null
        },
        manufacturerUrl: '',
        smallImage: '',
        smallImageSrcPrefixed: '',
        imageSrc: '',
        heroImageSrc: '',
        bgImageSet: '',
        tags: [],
        nutritionalNotes: '',
        countries: [],
        extraBanner: false,
        volume: ''
      },
      manufacturer: {
        image_url: null,
        maker_description: null,
      },
      updating: false
    }
  },
  getters: {
    getFirstAvailableStock: ({ apiProduct: { stocks } }) => stocks?.find(stock => stock.is_enabled && stock.is_in_stock) || null,
    getApiProduct: ({ apiProduct }) => apiProduct,
    getApiV1Product: ({ apiV1Product }) => apiV1Product,
    getMetaProduct: ({ metaProduct }) => metaProduct,
    getCalculated: ({ calculated }) => calculated,
    isUpdating: ({ updating }) => updating,
    isVisible: ({ calculated: { isVisible } }) => isVisible,
  },
  actions: {
    async refreshProduct({ productId, campaignId }) {
      const { UtilityHelper, $router } = await _getModules()

      this.updating = true
      this.metaProduct = _metaProduct()

      try {
          await this.fetchApiV1Product(productId, campaignId)
          await this.fetchApiProduct(productId, campaignId)
          this.fetchMetaProduct(productId, campaignId)

      } catch (e) {
        UtilityHelper.noticeNRError(e, `Failed to fetch product API. url: ${$router.path}`)
        console.error(e)
        await $router.replace({ name: 'error' })
      }
      this.updating = false
    },
    async refreshCalculatedFields(apiProduct) {
      const {
        UtilityHelper,
        CookieHelper,
        COOKIES,
        PRODUCT_TYPES,
        BACKGROUNDS,
        SiteConfig,
        createPriceObject,
        $router
      } = await _getModules()

      const isSimple = apiProduct.type === PRODUCT_TYPES.SIMPLE || apiProduct.bundle_items?.length === 1
      const isBundle = apiProduct.type === PRODUCT_TYPES.BUNDLE

      const bgImageSet = isBundle ? BACKGROUNDS.BUNDLE : BACKGROUNDS.SIMPLE

      const backToReferrer = SiteConfig.pageRoot + apiProduct.back_to_referrer_link
      const manufacturerUrl = SiteConfig.pageRoot + apiProduct.manufacturer_link

      const mmEnabled = CookieHelper.getCookie(COOKIES.MILES_AND_MORE_ENABLED)

      const prefixedImg = UtilityHelper.prefixImage(apiProduct.images.small_image, false)
      const prefixedHImg = UtilityHelper.prefixImage(apiProduct.images.image, true)

      const smallImageSrcPrefixed = UtilityHelper.prefixImage(apiProduct.images.small_image, false).retina_src
      const imageSrc = UtilityHelper.generateSrcSet(prefixedImg.src, prefixedImg.retina_src)
      const heroImageSrc = UtilityHelper.generateSrcSet(prefixedHImg.src, prefixedHImg.retina_src)
      const smallImage = UtilityHelper.prefixImage(apiProduct.images.small_image)

      const tags = (apiProduct.tags ?? '').split(',')
      const isVisible = apiProduct.is_enabled

      const preSelectedQty = +$router.currentRoute.query.qty || apiProduct.pre_selected_qty
      const priceObject = await createPriceObject(apiProduct)
      const nutritionalNotes = (apiProduct.details.nutritional_note ?? []).join(', ')
      const nutritionalData = apiProduct.nutritional_data ?? {}
      const countries = apiProduct.details.country ? [apiProduct.details.country] : []
      const extraBanner = SiteConfig.siteId === 10000 && $router.currentRoute.path.includes('-vorpremiere')
      const volume = await _getVolume(apiProduct)

      this.calculated = {
        smallImage,
        isSimple,
        isBundle,
        backToReferrer,
        mmEnabled,
        isVisible,
        preSelectedQty,
        priceObject,
        manufacturerUrl,
        smallImageSrcPrefixed,
        imageSrc,
        heroImageSrc,
        bgImageSet,
        tags,
        nutritionalNotes,
        nutritionalData,
        countries,
        extraBanner,
        volume
      }
    },
    async fetchApiProduct(productId, campaignId) {
      const { ApiController, PDP_CONFIG, $router } = await _getModules()

      const apiProduct = await ApiController.fetchProduct(productId, campaignId, PDP_CONFIG)
      const { redirect_url: redirect } = apiProduct
      const redirectWithoutDomain = redirect?.replace(/https?:\/\/[^/]+/, '')

      if (redirectWithoutDomain && redirectWithoutDomain !== $router.currentRoute.fullPath) {
        await $router.replace(redirectWithoutDomain)
        return
       }

      await _checkProductState(apiProduct)

      await this.refreshCalculatedFields(apiProduct)
      this.apiProduct = apiProduct
    },
    fetchMetaProduct(productId, campaignId) {
      setTimeout(async () => {
        const { TrackingHelper, ApiController } = await _getModules()

        void TrackingHelper.sendUtmParams({ productId, campaignId })

        TrackingHelper.productView(productId, campaignId)
        this.metaProduct = await ApiController.fetchMetaProduct(productId, campaignId)
      }, 0)
    },
    async fetchApiV1Product(productId, campaignId) {
      // this is a temporary solution to fetch the additional product data from the old API, till VIP-10085 & VIP-10086 are done
      const { ApiController, PDP_CONFIG_V1 } = await _getModules()

      const apiV1Product = await ApiController.fetchApiV1Product(productId, campaignId, PDP_CONFIG_V1)
      await _checkProductState(apiV1Product)

      this.apiV1Product = apiV1Product
    }
  }
})

const _checkProductState = async ({ is_opc: isOpc, is_enabled: enabled }) => {
  const { customerStore, $router } = await _getModules()
  if (isOpc) {
    $router.replace($router.currentRoute.fullPath.replace('-pdp-', '-opc-'))
    return
  }
  if (enabled) return

  await customerStore.waitUntilPending()
  if (customerStore.customerIsAdmin) return
  throw new Error('Disabled product or OPC')
}

const _getVolume = async (product) => {
  const { TranslationHelper, filters } = await _getModules()

  const typeTranslation = TranslationHelper.translate(_getUnitTypeKet(product.unit_type))
  const translation = TranslationHelper.translate(product.unit_type === 'piece'
    ? 'pdp_weight_per' : 'pdp_contents_per', typeTranslation)

  const contents = filters.float(product.reference_unit_contents)

  return `${translation} ${contents} ${product.reference_unit.toUpperCase()}`

}

const _getUnitTypeKet = (unit) => {
  if (unit === 'package') return 'fe_app_pdp_package'
  if (unit === 'piece') return 'pdp_attribute_slice'

  return 'fe_app_pdp_bottle'
}

const _metaProduct = () => ({
  url: null,
  product_id: null,
  campaign_id: null,
  is_enabled: null,
  attribute_set: null,
  type: null,
  name: null,
  manufacturer: null,
  manufacturer_url: null,
  tags: null,
  image_src: null,
  image_retina_src: null,
  reason_why_one: null,
  successor_url: null,
  show_catch_phrase: null,
  ratings_count: null,
  ratings_average: null,
  heart_ratings: null,
  catch_phrase: null,
  awards: null,
  last_purchase_date: null,
  has_been_bought: null,
  rating: null,
  milesandmore: {},
  recommendation: {},
  price: null,
  wishlist_item_id: null,
  contents: null,
  image_hero_src: null,
  image_hero_retina_src: null,
  vendor: null,
  wine_alcohol_concentration: null,
  distributor: null,
  legals: null,
  legal_information_string: null,
  contains_sulfide: null,
  delivery: null,
  qty: null,
  ga_data: null,
  expert_reviews: null,
  wine_maker_products: null,
  characteristics: null,
  bundle_items: null,
  score_info: null
})

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: TranslationHelper },
      { default: UtilityHelper, BACKGROUNDS },
      { default: ApiController },
      { default: TrackingHelper },
      { default: $router },
      { COOKIES, PRODUCT_TYPES },
      { default: SiteConfig },
      { default: CookieHelper },
      { useCustomerStore },
      { default: AnalyticsItemFactory },
      { default: GA4Controller },
      { default: filters },
      {
        createPriceObject,
        getUnitPrice,
        getSavings,
        getFirstAvailableStock,
        isInStock,
        PDP_CONFIG,
        PDP_CONFIG_V1,
      }
    ] = await Promise.all([
      await import('@/services/helpers/TranslationsHelper'),
      await import('@/services/helpers/UtilityHelper'),
      await import('@/services/ApiController'),
      await import('@/services/helpers/TrackingHelper'),
      await import('@/router'),
      await import('@/constants/GlobalConstants'),
      await import('@/services/SiteConfig'),
      await import('@/services/helpers/CookieHelper'),
      await import('@/stores/customer'),
      await import('@/modules/tag_manager/AnalyticsItemFactory'),
      await import('@/services/analytics/GA4Controller'),
      await import('@/services/Filters'),
      await import('@/services/helpers/ProductHelper'),
    ])
    cache = {
      UtilityHelper,
      TrackingHelper,
      TranslationHelper,
      ApiController,
      $router,
      COOKIES,
      PRODUCT_TYPES,
      SiteConfig,
      CookieHelper,
      AnalyticsItemFactory,
      GA4Controller,
      customerStore: useCustomerStore(),
      createPriceObject,
      getUnitPrice,
      getSavings,
      getFirstAvailableStock,
      isInStock,
      filters,
      BACKGROUNDS,
      PDP_CONFIG,
      PDP_CONFIG_V1
    }
    return cache
  }
})()
