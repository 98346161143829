import { defineStore } from 'pinia'

let pendingVars = []

export const DEFAULTS = [
  'shop_service_hotline',
  'shop_service_email',
  'customer_testimonials',
  'wm_testimonials',
  'new_lg_selector'
]

export const useCmsVariables = defineStore('cmsVariables', {
  state: () => ({ variables: {} }),
  getters: {
    getContent: ({ variables }) => (key) => variables[key]
  },
  actions: {
    update(data) {
      this.variables = {
        ...this.variables, ...data.reduce((col, { identifier, value }) => ({
          ...col,
          [identifier]: value
        }), {})
      }
    },
    async fetchOne(id) {
      if (this.variables[id]) return

      await _fetchVariable(id, this.update)
    },
    async fetchDefaults() {
      DEFAULTS.forEach((id) => {
        _fetchVariable(id, this.update)
      })
    }
  },
})

const _fetchVariable = (() => {
  const DEBOUNCE_TIME = 1000 // ms
  let timeout

  return async (id, cb) => {
    if (pendingVars.includes(id)) return

    clearTimeout(timeout)
    pendingVars = [...pendingVars, id]
    timeout = setTimeout(_getHandler(cb), DEBOUNCE_TIME)
  }
})()

const _getHandler = (cb) => {
  return async () => {
    const { ApiController } = await _getModules()
    try {
      const data = await ApiController.fetchCmsVariable(pendingVars)
      cb(data)
      pendingVars = []
    } catch (e) {
      console.warn('Error fetching while cms variables ' + pendingVars.join(', '), e)
    }
  }
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const { API_URL } = await import('@/constants/GlobalConstants')
    const { default: RequestHelper } = await import('@/services/helpers/RequestHelper')
    const { default: UtilityHelper } = await import('@/services/helpers/UtilityHelper')
    const { default: ApiController } = await import('@/services/ApiController')

    cache = { RequestHelper, UtilityHelper, API_URL, ApiController }
    return cache
  }
})()
