import { defineStore } from 'pinia'

export const useOverlayStore = defineStore('overlay', {
  state() {
    return { visible: false }
  },
  getters: {
    isVisible: ({ visible }) => visible
  },
  actions: {
    changeVisibility ({ isVisible }) {
      this.visible = isVisible
    }
  }
})
