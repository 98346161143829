import { defineStore } from 'pinia'
import Query from '@/modules/category/Query'

let debounce
let activeFilters = {}
let queryStash

const NO_SEARCH = 'pagination_page=1'

export const useSearchConfig = defineStore('searchConfig', {
  state: () => ({
    products: [],
    config: {
      category: {
        sort_options: [],
        attributes: [],
        is_enabled: true
      },
      search: '',
      results: [],
      query: {},
      filters: {},
      totalCount: 0,
      totalPages: 0,
      error: false
    },
    loading: false,
    displaySolrData: false
  }),
  getters: {
    activeAttributes: ({ config: { filters } }) => Object.values(filters).map((filter) => Object.values(filter)).flat(),
    activeFilters: ({ config: { filters } }) => Object.keys(filters),
    hasSolrData: ({ displaySolrData }) => displaySolrData,
    categoryIsLoading: ({ loading }) => loading,
    category: ({ config: { category } }) => category,
    getConfig: ({ config }) => config,
    getProducts: ({ products }) => products,
    manufacturers: ({ config: { results } }) => {
      const entities = results?.filter(({ entity_type: et }) => et === 'manufacturer') ?? []
      // priority if: only 1 manufacturer is present, first in the results (-> the highest rank in score: tested)
      const priority = entities[0] === results?.[0]
      return { entities, priority }
    },
    filterCount: ({ config: { filters } }) => Object.values(filters).reduce((c, filter) => c + Object.keys(filter).length, 0),
    blocks: ({ config: { category } }) => ({
      before: { html: category.before_html, css: category.before_html_css },
      after: { html: category.after_html, css: category.after_html_css }
    }),
    elbwalkerContext({ config: { category, totalCount } }) {
      const currentPage = +this.$router.currentRoute.query.pagination_page || 1
      const context = [
        'shopping:discover',
        `product_sort:${category.sort}`,
        `product_count:${totalCount}`,
        `product_page:${currentPage}`
      ]
      let filterCount = 0
      Object.entries(this.getConfig.filters).forEach(([attribute, options]) => {
        context.push(`filter_${attribute}:${Object.keys(options).join(',')}`)
        filterCount += Object.keys(options).length
      })
      context.push(`filter_count:${filterCount}`)
      return context
    }
  },
  actions: {
    async fetchManufacturerProducts(manufacturerId) {
      this.config.error = false
      const { ApiController } = await _getModules()
      const [, filters, search] = await _getUrlData(this.$router)
      activeFilters = filters

      try {
        this.loading = true
        const res = await ApiController.fetchManufacturerProducts(manufacturerId, search || NO_SEARCH)
        res.data.category.attributes = res.data.category.attributes.map(attr => ({ ...attr, is_top_filter: true }))

        this.config = _getConfig(res, res)

        void this.fetchProducts()
      } catch (_) {
        this.config.error = true
      }
      this.loading = false
    },
    async fetchCategory() {
      this.config.error = false
      const { ApiController, TrackingHelper } = await _getModules()
      const route = this.$router.currentRoute

      if (route.name === 'manufacturer') {
        await this.fetchManufacturerProducts(route.params.id)
        return
      }

      const [categoryId, filters, search] = await _getUrlData(this.$router)
      activeFilters = filters
      const cacheKey = search || NO_SEARCH

      try {
        this.loading = true

        const completedSearch = categoryId ? `category_id=${categoryId}&${search}` : search
        const res = await ApiController.fetchCategory(completedSearch)

        const redirect = await _safetyCheck(res)

        if (redirect) {
          await this.$router.replace(redirect)
          return
        }

        this.config = _getConfig(res, cacheKey)
        void TrackingHelper.sendUtmParams()
        TrackingHelper.sendCategoryPageView(this.category.code)

        void this.fetchProducts()
      } catch (e) {
        this.config.error = true
      }
      this.loading = false
    },
    async fetchProducts() {
      const { MetaProduct } = await _getModules()

      this.products = this.config.results?.filter(product => product.entity_type === 'product')
        .map(({ product_id, campaign_id, score_info }) => new MetaProduct({
            product_id,
            campaign_id,
            score_info,
          })
        ) ?? []
    },
    updateCategory({ group, value, checked = undefined, immediate = true }) {
      _setupQuery(checked, group, value)

      if (!immediate) return

      clearTimeout(debounce)
      // boomer timer <3
      debounce = setTimeout(() => {
        void this.applyFilter()
        debounce = null
      }, 500)
    },
    resetFilter(group = null) {
      _clearQuery(group)
    },
    async applyFilter() {
      try {
        await this.$router.push({ query: queryStash.getSearchParams() })
      } catch (_) {
        // changing filters back and forth could lead to duplicate navigation error
      }
      queryStash = new Query()
    }
  }
})


const _getUrlData = async ({ currentRoute }) => {
  const params = new Query().getSearchParams()
  const filters = Object.keys(params).reduce((filters, key) => ({
    ...filters,
    [key]: (params[key] + '').split(';')
  }), {})

  return [
    currentRoute.params.id,
    filters,
    decodeURIComponent(currentRoute.fullPath.split('?')[1] ?? '')
  ]
}

/**
 * collect filters by clustering them: { attribute: { activeOptionName: { ...activeOption }}}
 */
const _activeFilterReducer = (cur, { code: group, name: groupLabel, options }) => {
  const activeAttrs = activeFilters[group] ?? []
  const activeOpts = options.reduce((cur, { name, label }) =>
    activeAttrs.includes(String(name)) ? { ...cur, [name]: { groupLabel, group, name, label } } : cur, {})

  return Object.keys(activeOpts).length ? { ...cur, [group]: activeOpts } : cur
}

const _setupQuery = (checked, group, value) => {
  if (!(queryStash instanceof Query)) {
    queryStash = new Query()
  }
  queryStash.setPaginationPage(1)
  if (checked !== undefined) {
    checked
      ? queryStash.setSearchParam(group, value, true)
      : queryStash.removeSearchParam(group, value)
  } else {
    queryStash.setSearchParam(group, value)
  }
}

const _clearQuery = (group) => {
  if (!(queryStash instanceof Query)) {
    queryStash = new Query()
  }
  group ? queryStash.removeSearchParam(group) : queryStash.clearSearchParams()
}

const _getConfig = ({ data, headers }, search) => {
  const totalCount = +headers['x-total-count']
  const totalPages = +headers['x-total-pages']
  const filters = data.category.attributes.reduce(_activeFilterReducer, {})
  const rewrite = data.rewrite
  const error = false

  return { ...data, totalCount, totalPages, search, filters, rewrite, error }
}

const _safetyCheck = async ({ data }) => {
  const redirect = data.redirect_url
  if (redirect) return redirect

  if (data?.category?.is_enabled) return null

  const { customerStore } = await _getModules()
  await customerStore.waitUntilPending()

  return customerStore.customerIsAdmin ? null : { name: 'home' }
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: ApiController },
      { default: TrackingHelper },
      { default: MetaProduct },
      { useCustomerStore },
    ] = await Promise.all([
      await import('@/services/ApiController'),
      await import('@/services/helpers/TrackingHelper'),
      await import('@/modules/product/MetaProduct'),
      await import('@/stores/customer')
    ])
    const customerStore = useCustomerStore()
    cache = { ApiController, TrackingHelper, MetaProduct, customerStore }
    return cache
  }
})()
