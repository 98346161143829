import { defineStore } from 'pinia'

const defaultState = {
  canonical_href: null,
  default_utm_campaign: null,
  default_utm_content: null,
  default_utm_medium: null,
  default_utm_source: null,
  default_utm_term: null,
  main_content: null,
  main_content_css: null,
  main_content_js: null,
  meta_description: null,
  meta_keywords: null,
  meta_title: null,
  name: null,
  page_id: null,
  page_type: null,
  robots: null,
  status: null,
  title: null
}

export const useCmsPagesStore = defineStore('cmsPages', {
  namespaced: true,
  state: () => ({
    entity: { ...defaultState }
  }),
  getters: {
    page: ( { entity }) => entity,
    mainContent: ({ entity: { main_content: mainContent } }) => mainContent,
    mainContentCss: ({ entity: { main_content_css: mainContentCss } }) => mainContentCss,
    mainContentJs: ({ entity: { main_content_js: mainContentJs } }) => mainContentJs
  },
  actions: {
    async fetchCmsPage(pageId) {
      const { ApiController, TrackingHelper } = await _getModules()
      this.entity = { ...defaultState }
      try {
        this.entity = await ApiController.fetchCmsPage (pageId)
        await TrackingHelper.sendUtmParams()
      } catch (e) {
        if ([401, 403].includes(e.status)) {
          location.replace(`login?redirect_uri=${location.pathname}`)
          return
        }
        return Promise.reject(e)
      }
    }
  }
})

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: ApiController },
      { default: TrackingHelper },
      { default: $router }
    ] = await Promise.all([
      await import('@/services/ApiController'),
      await import('@/services/helpers/TrackingHelper'),
      await import('@/router')
    ])
    cache = { ApiController, TrackingHelper, $router }
    return cache
  }
})()
