import { defineStore } from 'pinia'

export const useSoldOutProducts = defineStore('soldOutProducts', {
  state () {
    return { products: [] }
  },
  getters: {
    items: ({ products }) => products,
    totalCount: ({ products }) => products?.length
  },
  actions: {
    async refreshSoldOutProducts () {
      const {
        CartItem,
        UtilityHelper,
        API_URL,
        ApiController,
        fetchProductWithIndex,
        matchItemWithProduct,
        cart
      } = await _getModules()

      try {
        const url = UtilityHelper.formatString(API_URL.CART.SOLD_OUT_PRODUCTS, cart.cartId)
        const soldOutItems = await ApiController.fetchProducts(url)

        if (soldOutItems.length) {
          const prepared = soldOutItems.map(item => new CartItem(item))
          const indexed = await fetchProductWithIndex(soldOutItems)
          // give time to rendering before we trigger the sections
          setTimeout(() => {
            this.products = matchItemWithProduct(prepared, indexed, { endpoint: url })
          }, 500)
        }

      } catch (e) {
        console.error('Failed to load sold out items', e)
      }
    }
  }
})

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: CartItem },
      { default: ApiController },
      { default: UtilityHelper },
      { API_URL },
      { fetchProductWithIndex, matchItemWithProduct },
      { useCartStore }
    ] = await Promise.all([
      await import('@/modules/product/CartItem'),
      await import('@/services/ApiController'),
      await import('@/services/helpers/UtilityHelper'),
      await import('@/constants/GlobalConstants'),
      await import('@/services/helpers/BulkProductMatcher'),
      await import('@/stores/cart')
    ])
    cache = { CartItem, ApiController, UtilityHelper, API_URL, fetchProductWithIndex, matchItemWithProduct, cart: useCartStore() }
    return cache
  }
})()
