import { defineStore } from 'pinia'

export const useManufacturerStore = defineStore('manufacturer', {
  state: () => ({
    entity: {
      manufacturer_id: null,
      name: null,
      best_sites: null,
      cellarmaster: null,
      manager: null,
      established_year: null,
      production_amount: null,
      size: null,
      image_logo: null,
      image_manager: null,
      manufacturer_landing_page_teaser_image: null,
      country: null,
      region: null,
      short_description: null,
      description: null,
      emotional_maker_teaser_image: null,
      meta_title: null,
      meta_description: null,
      maker_name: null,
      maker_description: null,
      maker_reason_why_one: null,
      maker_reason_why_two: null,
      reason_why_one: null,
      reason_why_two: null,
      reason_why_three: null,
      hero_image: null,
      maker_quote: null,
      canonical_for: null,
      robots: null,
      background_info: null,
      terroir: null,
      manufacturer_variant_id: null,
      is_vdp_member: null,
      is_bio_certified: null,
      memberships: null,
      country_name: null,
      region_name: null,
      status: 0,
      name_prefix: 0,
      portrait: null
    },
    calculatedData: {
      landingTeaserImage: '',
      makerImage: '',
      heroImage: '',
      bgImage: '',
      logo: '',
      links: {}
    },
  }),
  getters: {
    loading: ({ entity }) => !entity.manufacturer_id,
    manufacturer: ({ entity }) => entity,
    calculated: ({ calculatedData }) => calculatedData
  },
  actions: {
    async fetchManufacturer(id) {
      const { router, ApiController, TrackingHelper } = await _getModules()
      try {
        this.entity = await ApiController.fetchManufacturer(id, true)
        await this.updateCalculated()
        void TrackingHelper.sendUtmParams()
      } catch (e) {
        console.error(e)
        await router.replace({ name: 'error' })
      }
    },
    async updateCalculated() {
      const [
        heroImage,
        bgImage,
        logo,
        vdpLogo,
        landingTeaserImage,
        makerImage,
        regionLink,
        countryLink
      ] = await Promise.all([
        await _getHeroImage(this.entity.hero_image, this.entity.name, '{0}media/cache/{1}/hero_image/825x331/proportional/x/901b80b8ef/{2}'),
        await _buildUrl(' ', '{0}media/uploads/{1}/images/bgs/bg_landscape.jpg'),
        await _buildUrl(this.entity.image_logo, '{0}media/cache/{1}/image_logo/210x160/crop_top/x/6b8061d448/{2}'),
        await _buildUrl(' ', '{0}static_40483c6cb02b627e5f70bbe12477bba9/interfaces/platform/v2/static/images/pdp/badge/vdp.svg'),
        await _buildUrl(this.entity.manufacturer_landing_page_teaser_image, '{0}media/cache/{1}/hero_image/825x331/proportional/x/901b80b8ef/{2}'),
        await _buildUrl(
          this.entity.emotional_maker_teaser_image,
          '{0}media/cache/{1}/emotional_maker_teaser_image/724x290/proportional/x/3941911d85/{2}'
        ),
        await _href('region', this.entity.region_name),
        await _href('country', this.entity.country_name)
      ])

      const links = { region_name: regionLink, country_name: countryLink }

      this.calculatedData = { heroImage, bgImage, logo, vdpLogo, landingTeaserImage, makerImage, links }
    }
  }
})

const _href = async (attribute, value) => {
  const { UtilityHelper, TranslationsHelper, FILTER } = await _getModules()

  return UtilityHelper.formatString(FILTER, TranslationsHelper.translate('fe_app_pdp_wines').toLowerCase(), attribute, value)
}

const _getHeroImage = (uri, name, template) => {
  if (uri) {
    return  _buildUrl(uri, template)
  }

   return require(`@/assets/images/manufacturer/default_${(name.length % 2 )+ 1}.webp`)
}

const _buildUrl = async (uri, template) => {
  if (!uri) return null

  const { UtilityHelper, SiteConfig: { pageRoot } } = await _getModules()

  return UtilityHelper.formatString(template, pageRoot, 10000, uri)
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: router },
      { default: ApiController },
      { default: SiteConfig },
      { default: UtilityHelper },
      { default: TrackingHelper },
      { default: TranslationsHelper },
      { PAGE_URL: { FILTER } }
    ] = await Promise.all([
      await import('@/router'),
      await import('@/services/ApiController'),
      await import('@/services/SiteConfig'),
      await import('@/services/helpers/UtilityHelper'),
      await import('@/services/helpers/TrackingHelper'),
      await import('@/services/helpers/TranslationsHelper'),
      await import( '@/constants/GlobalConstants')
    ])
    cache = {
      router,
      ApiController,
      SiteConfig,
      UtilityHelper,
      TrackingHelper,
      TranslationsHelper,
      FILTER
    }
    return cache
  }
})()
